.root {
	position: relative;
	width: 256px;
	background-color: var(--color-generic-white);
	border: 1px solid var(--color-neutral-300);
	border-radius: 8px;
	cursor: pointer;

	.date-picker {
		visibility: hidden;
		padding: 10px 12px;
	}

	.custom-date-picker {
		padding: 8px 12px;
		display: flex;
		align-items: center;
		position: absolute;
		left: 0;
		top: 0px;
		font-weight: 400;
		font-size: 16px;
		color: var(--color-neutral-900);

		.calendar-icon {
			margin-right: 10px;
		}
	}
}
