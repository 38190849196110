@import "Themes/constants.scss";

.root {
	position: relative;

	.label {
		position: relative;
		z-index: 2;
		display: flex;
		justify-content: space-between;
		align-items: center;
		white-space: nowrap;
		padding: 8px 16px;
		font-weight: 500;
		font-size: 16px;
		color: var(--color-neutral-500);
		border: 1px solid var(--color-neutral-300);
		border-radius: 4px;
		background-color: var(--color-generic-white);
		transition: color 0.2s $custom-easing;
		user-select: none;
		cursor: pointer;

		.arrow-down-icon {
			margin-left: 8px;
			transition: transform 0.2s $custom-easing;

			path {
				stroke: var(--color-neutral-500);
			}
		}
	}

	.label-open {
		z-index: 4;
		color: var(--color-neutral-900);

		.arrow-down-icon {
			transform: scaleY(-1);
			path {
				stroke: var(--color-neutral-900);
			}
		}
	}

	.background {
		position: fixed;
		z-index: 3;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}

	.options-container {
		position: absolute;
		z-index: 10;
		padding: 8px;
		width: max-content;
		top: 46px;
		right: 0;
		border: 1px solid var(--color-neutral-300);
		border-radius: 8px;
		background-color: var(--color-generic-white);
		animation: fadeIn 0.2s $custom-easing;

		.option {
			display: flex;
			align-items: center;
			padding: 12px;

			input[type="checkbox"] {
				margin: 0;
				width: 15px;
				height: 15px;
				cursor: pointer;
			}

			.option-label {
				margin-left: 10px;
				font-weight: 500;
				font-size: 16px;
				color: var(--color-neutral-500);
				user-select: none;
				cursor: pointer;
			}
		}

		.select-all-button {
			display: flex;
			justify-content: center;
			margin-top: 8px;
			width: 100%;
		}
	}

	.right {
		right: 0;
	}

	.left {
		left: 0;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
