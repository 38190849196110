@import "Themes/constants.scss";

.root {
	min-width: 256px;
	min-height: calc(100vh - 80px);
	height: auto;
	background-color: var(--color-generic-white);

	@media screen and (max-width: $screen-xs) {
		display: none;
	}

	svg {
		min-width: 24px;
		min-height: 24px;
	}

	.content {
		padding: 16px;
		position: fixed;
		top: 0;
		left: 0;
		height: calc(100vh - 80px);
		min-width: 256px;
		overflow-y: scroll;
		background-color: var(--color-generic-white);

		&::-webkit-scrollbar {
			display: none;
		}

		.logo-container {
			padding: 12px 8px;
			margin-bottom: 24px;
			display: flex;
			align-items: center;

			.logo {
				max-width: 224px;
				max-height: 32px;
			}
		}

		.button-container {
			margin: 16px 8px;
		}

		.menu-container {
			padding: 16px 8px;
			display: flex;
			align-items: center;
			cursor: pointer;

			&:hover {
				.label {
					color: var(--color-neutral-700);
				}

				svg {
					path {
						stroke: var(--color-neutral-900);
					}
				}
			}

			svg {
				path {
					stroke: var(--color-neutral-500);
					transition: stroke 0.2s linear;
				}
			}

			.label {
				margin-left: 10px;
				font-weight: 500;
				font-size: 16px;
				color: var(--color-neutral-500);
				transition: color 0.2s linear;
				user-select: none;
			}
		}
		.menu-buttons {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			padding: 16px 0;
			gap: 16px;
			align-self: stretch;

			.documents-icon {
				display: flex;
				margin-bottom: 8px;
				padding: 8px 12px;
				justify-content: center;
				align-items: center;
				width: 100%;
				align-self: stretch;
				border-radius: 6px;
				background: var(--color-primary-500);
			}
			.verification-icon {
				display: flex;
				margin-bottom: 8px;
				padding: 8px 12px;
				width: 100%;
				justify-content: center;
				align-items: center;
				align-self: stretch;
				border-radius: 6px;
				background: var(--primary-50, #f5f5ff);
			}
		}

		.active {
			.label {
				color: var(--color-primary-500);
			}

			svg {
				path {
					stroke: var(--color-primary-500);
				}
			}
		}

		.divider {
			margin: 16px 0;
			width: 100%;
			height: 1px;
			background-color: var(--color-neutral-300);
		}
		.bottom-container {
			display: flex;
			position: absolute;
			flex-direction: column;
			align-items: center;
			gap: 8px;
			align-self: stretch;
			bottom: 16px;
			width: 90%;
			.help {
				display: flex;
				align-items: center;
				justify-content: center;
				left: 0;
				text-align: center;
				cursor: pointer;
				width: 100%;
			}

			.logout {
				display: flex;
				align-items: center;
				justify-content: center;
				left: 0;
				text-align: center;
				cursor: pointer;
			}
		}
	}
	.help-button{
		display: flex;
		align-items: center;
		justify-content: center;
		left: 0;
		text-align: center;
		cursor: pointer;
		width: 64%;
	}

	.notifications-background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 3;
	}
}

.root-medium {
	min-width: 72px;
	transition: width 0.2s linear;

	.content {
		z-index: 10;
		min-width: unset;
		width: 72px;
		transition: width 0.2s linear;

		.menu-container {
			.label {
				visibility: hidden;
			}
		}
	}

	&[data-is-open="true"] {
		.content {
			width: 256px;

			.arrow-icon {
				animation: arrowXTrue 0.2s $custom-easing forwards;
			}

			.menu-container {
				.label {
					visibility: visible;
					animation: fadeIn 0.2s linear;
				}
			}
		}

		.mask {
			width: 256px;
		}
	}

	&[data-is-open="false"] {
		.content {
			.arrow-icon {
				animation: arrowXFalse 0.2s $custom-easing forwards;
			}

			.menu-container {
				.label {
					visibility: hidden;
					animation: fadeOut 0.2s linear;
				}
			}
		}
	}

	.mask {
		position: fixed;
		bottom: 0;
		width: 72px;
		height: 80px;
		z-index: 2;
		background-color: var(--color-generic-white);
		transition: width 0.2s linear;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		visibility: visible;
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes arrowXFalse {
	0% {
		transform: scaleX(-1);
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		transform: scaleX(1);
		opacity: 1;
	}
}

@keyframes arrowXTrue {
	0% {
		transform: scaleX(1);
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		transform: scaleX(-1);

		opacity: 1;
	}
}
