@import "Themes/constants.scss";

.root {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	z-index: 2;
	bottom: -80px;
	padding: 0 24px;
	width: 100%;
	height: 80px;
	background-color: var(--color-generic-white);

	@media screen and (max-width: $screen-xs) {
		position: relative;
		bottom: 0;
	}

	.text {
		padding-left: 32px;
		margin: 0;
		font-weight: 400;
		font-size: 14px;
		color: var(--color-neutral-400);
	}

	.logo-container {
		display: flex;
		align-items: center;

		.logo {
			max-width: 224px;
			max-height: 32px;
		}
	}
}
