@import "Themes/constants.scss";

.root {
	position: relative;

	.row {
		position: relative;
		z-index: 1;

		.cell {
			white-space: nowrap;
			padding: 16px 24px;
			font-weight: 600;
			font-size: 14px;
			text-align: left;
			color: var(--color-neutral-900);
			user-select: none;
			//cursor: pointer;

			.sort-icon {
				vertical-align: top;
			}
		}
	}

	.background {
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 100%;
		border-radius: 8px;

		background-color: var(--color-neutral-50);
	}
}
