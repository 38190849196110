@import "Themes/constants.scss";

.root {
	.title-container {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.title {
			font-weight: 700;
			font-size: 40px;
			letter-spacing: -0.02em;
			color: var(--color-neutral-900);
		}
		.description {
			color: var(--color-neutral-900);
			font-feature-settings: "salt" on;
			/* Paragraph/Medium/Regular */
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px; /* 150% */
		}
		.document-already-anchored {
			display: inline-flex;
			min-width: 264px;
			max-width: 520px;
			padding: 16px;
			align-items: flex-start;
			justify-content: center;
			gap: 16px;
			border-radius: 8px;
			background: var(--warning-50, #fffbeb);
			color: var(--warning-700, #b45309);
			font-feature-settings: "salt" on;

			/* Paragraph/Small/SemiBold */
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px; /* 171.429% */
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		gap: 32px;
		.hash-container {
			margin-top: 64px;
			border-radius: 16px;
			border: 1px dashed var(--neutral-500, #6b7280);
			display: flex;
			padding: 16px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 24px;
			align-self: stretch;

			.deposit-container {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				padding: 24px;
				gap: var(--spacing-1, 8px);
				align-self: stretch;

				.drag-to-deposit {
					color: var(--neutral-900, #111827);
					font-feature-settings: "salt" on;

					/* Paragraph/Small/Medium */
					font-family: Inter;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}

				.or {
					color: var(--neutral-500, #6b7280);
					font-feature-settings: "salt" on;

					/* Paragraph/Small/Regular */
					font-family: Inter;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px; /* 171.429% */
				}

				.select-files {
					padding: 6px 12px;
					border-radius: 6px;
					border: 1px solid var(--neutral-300, #d1d5db);
				}
				.title-input-container {
					display: flex;
					flex-direction: column;
					gap: 8px;
					align-self: stretch;
					.title-input-label {
						color: var(--neutral-900, #111827);
						font-feature-settings: "salt" on;
						font-family: Inter;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 24px;
					}
					.title-input {
						display: flex;
						padding: 8px var(--spacing-15, 12px);
						align-items: center;
						gap: 16px;
						flex: 1 0 0;
						align-self: stretch;
						border: 1px solid var(--neutral-300, #d1d5db);
						border-radius: 6px;
						background: var(--generic-white, #fff);
					}
				}
			}

			.table-container {
				width: 100%;
				overflow: auto;
				.table {
					padding: 8px;
					border-radius: 8px;
					background-color: var(--color-generic-white);
					min-width: 100%;

					.header {
						position: relative;
						.row {
							position: relative;
							z-index: 1;

							.cell {
								white-space: wrap;
								padding: 16px 24px;
								font-weight: 600;
								font-size: 14px;
								text-align: left;
								color: var(--color-neutral-900);
								user-select: none;
								//cursor: pointer;

								.sort-icon {
									vertical-align: top;
								}
							}

							.tooltip {
								width: 24px;
							}
						}

						.background {
							position: absolute;
							z-index: 0;
							width: 100%;
							height: 100%;
							border-radius: 8px;
							background-color: var(--color-neutral-50);
						}
					}

					.body {
						.row {
							border-bottom: 1px solid var(--color-neutral-300);
							.hash-content {
								min-width: 70%;
							}
							.cell {
								white-space: nowrap;
								padding: 16px 24px;
								font-weight: 400;
								font-size: 14px;
								text-align: left;
								color: var(--color-neutral-700);

								.hashing-files {
									display: flex;
									flex-direction: row;
									padding: var(--spacing-none, 0px);
									align-items: center;
									gap: var(--spacing-sm, 8px);
									.loader {
										width: 20px;
										height: 20px;
									}
									.loading-message {
										color: var(--color-neutral-500);
										/* text/xs/regular */
										font-family: Inter;
										font-size: 12px;
										font-style: normal;
										font-weight: 400;
										line-height: 20px; /* 166.667% */
									}
								}
								.hashing-success {
									display: flex;
									flex-direction: row;
									padding: var(--spacing-none, 0px);
									align-items: center;
									justify-content: flex-end;
									gap: 24px;
								}
								.check-icon {
									cursor: auto;
									width: 20px;
									height: 20px;
								}
								.cross-icon {
									display: flex;
									svg {
										width: 24px;
										height: 24px;
									}
								}
							}
							.cell:first-child {
								font-weight: 500;
							}

							.cell:last-child {
								cursor: pointer;
								border-bottom: none;
							}

							.text-red {
								display: flex;
								flex-direction: row;
								align-items: center;
								gap: 8px;
								color: var(--destructive-500-main, #ef4444);
							}
						}
					}
				}
			}
		}
		.alert-container {
			display: flex;
			padding: 16px;
			align-items: flex-start;
			gap: 16px;
			align-self: stretch;
			border-radius: 8px;
			background: var(--destructive-50, #fef2f2);
			color: var(--destructive-700, #b91c1c);
			font-feature-settings: "salt" on;
			/* Paragraph/Small/SemiBold */
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px; /* 171.429% */
		}

		.action-container {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: var(--spacing-1, 8px);
			align-self: stretch;
		}

		.deposit-in-progress-action {
			margin-top: 24px;
			display: flex;
			padding: 8px 12px;
			justify-content: center;
			align-items: center;
			gap: 6px;
		}

		.deposit-in-progress {
			margin-top: 64px 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			padding: 128px 24px 0 24px;
			gap: var(--spacing-1, 8px);
			align-self: stretch;
		}

		.deposit-title {
			color: var(--neutral-900, #111827);
			font-feature-settings: "salt" on;

			/* Paragraph/Medium/Semibold */
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px; /* 150% */
		}

		.deposit-subtitle {
			color: var(--neutral-500, #6b7280);

			/* text/sm/regular */
			font-family: Inter;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px; /* 171.429% */
		}
	}
}
