@import "Themes/constants.scss";

.loader {
	animation: spin 2s linear infinite;

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
}
