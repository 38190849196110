@import "Themes/constants.scss";

.root {
	position: fixed;
	z-index: 20;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	.background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
	}

	.container {
		position: relative;
		padding: 24px;
		width: 686px;
		max-height: 80vh;
		background: var(--color-generic-white);
		box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
		border-radius: 8px;
		overflow: auto;

		.header {
			display: flex;
			justify-content: space-between;
			margin-bottom: 24px;

			.cross {
				margin-left: 12px;
				cursor: pointer;
			}
		}
	}
}
