.root {
	width: 100%;
	background-color: var(--color-generic-white);
	padding: 24px 24px 0 24px;
	border-radius: 16px;

	.header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;

		.title-and-qte {
			.title {
				font-weight: 500;
				font-size: 16px;
				color: var(--color-neutral-500);
			}

			.qte {
				font-weight: 600;
				font-size: 36px;
				letter-spacing: -0.02em;
				color: var(--color-neutral-900);
			}
		}

		.detail {
			height: max-content;
			font-weight: 500;
			font-size: 14px;
			color: var(--color-primary-700);
			cursor: pointer;

			.arrow-icon {
				vertical-align: sub;

				path {
					stroke: var(--color-primary-700);
				}
			}
		}
	}
}
