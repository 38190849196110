@import "Themes/constants.scss";

.root {
	display: inline-flex;
	gap: 8px;
	align-items: center;
	font-weight: 500;
	font-family: var(--font-primary);
	border: none;
	user-select: none;
	cursor: pointer;
	transition: background-color 0.2s $custom-easing;

	&[variant="primary"] {
		color: var(--color-neutral-50);
		border-radius: 6px;
		background-color: var(--color-primary-500);

		&:hover {
			background-color: var(--color-primary-600);
		}

		&:active {
			background-color: var(--color-primary-700);
		}

		&:disabled {
			background-color: var(--color-primary-200);
			pointer-events: none;
		}
	}

	&[variant="secondary"] {
		color: var(--color-primary-800);
		border-radius: 6px;
		background: var(--color-primary-50, #F5F5FF);

		&:hover {
			background-color: var(--color-primary-100);
		}

		&:active {
			background-color: var(--color-primary-200);
		}

		&:disabled {
			color: var(--color-primary-200);
			background-color: var(--color-primary-50);
			pointer-events: none;
		}
	}

	&[variant="outlined"] {
		color: var(--color-neutral-600);
		border-radius: 6px;
		border: 1px solid var(--color-neutral-300);
		background-color: var(--color-generic-white);

		&:hover {
			background-color: var(--color-neutral-50);
		}

		&:active {
			background-color: var(--color-neutral-100);
		}

		&:disabled {
			color: var(--color-neutral-300);
			background-color: var(--color-generic-white);
			pointer-events: none;
		}
	}

	&[variant="link"] {
		color: var(--color-primary-700);
		border-radius: 6px;
		background-color: transparent;

		&:hover {
			color: var(--color-primary-800);
		}

		&:active {
			color: var(--color-primary-900);
		}

		&:disabled {
			color: var(--color-primary-200);
			pointer-events: none;
		}
	}

	&[variant="destructive"] {
		color: var(--color-neutral-50);
		border-radius: 6px;
		background-color: var(--color-destructive-500);

		&:hover {
			background-color: var(--color-destructive-600);
		}

		&:active {
			background-color: var(--color-destructive-700);
		}

		&:disabled {
			background-color: var(--color-destructive-200);
			pointer-events: none;
		}
	}

	&[sizing="xs"] {
		font-size: 12px;
		padding: 4px 10px;
	}

	&[sizing="s"] {
		font-size: 14px;
		padding: 6px 12px;
	}

	&[sizing="m"] {
		font-size: 16px;
		padding: 8px 12px;
	}

	&[sizing="l"] {
		font-size: 18px;
		padding: 12px 16px;
	}
	&[sizing="fullwidth"] {
		display: flex;
		padding: 8px 12px;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
}
