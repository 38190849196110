@import "Themes/constants.scss";

.root {
	position: fixed;
	z-index: 4;
	display: none;
	padding: 0 8px;
	width: 100%;
	height: 80px;
	background-color: var(--color-generic-white);

	@media screen and (max-width: $screen-xs) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.logo-container {
		display: flex;
		align-items: center;

		.logo {
			margin-left: 8px;
			max-height: 24px;
			max-width: 224px;
		}
	}

	.icons-container {
		display: flex;
		align-items: center;

		.notifications-icon {
			cursor: pointer;

			path {
				stroke: var(--color-neutral-900);
			}
		}

		.burger-menu-icon {
			margin-left: 32px;
			position: relative;
			z-index: 2;
			cursor: pointer;

			path {
				stroke: var(--color-neutral-900);
			}
		}
	}

	.background {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 1;
	}

	.menu-container {
		position: absolute;
		z-index: 2;
		right: 0;
		top: 80px;
		min-width: 224px;
		background-color: var(--color-generic-white);
		border-bottom-left-radius: 8px;

		.navlink-container {
			padding: 16px 8px;
			display: flex;
			align-items: center;
			cursor: pointer;

			&:hover {
				.label {
					color: var(--color-neutral-700);
				}

				svg {
					path {
						stroke: var(--color-neutral-900);
					}
				}
			}

			svg {
				path {
					stroke: var(--color-neutral-500);
					transition: stroke 0.2s linear;
				}
			}

			.label {
				margin-left: 10px;
				font-weight: 500;
				font-size: 16px;
				color: var(--color-neutral-500);
				transition: color 0.2s linear;
				user-select: none;
			}
		}

		.active {
			.label {
				color: var(--color-primary-500);
			}

			svg {
				path {
					stroke: var(--color-primary-500);
				}
			}
		}
	}
}
