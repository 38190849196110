@import "Themes/constants.scss";

.root {
	.top-container {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;

		@media screen and (max-width: $screen-s) {
			flex-direction: column;
			align-items: flex-start;
		}

		.title {
			margin-bottom: 64px;
			font-weight: 700;
			font-size: 40px;
			color: var(--color-neutral-900);
			letter-spacing: -0.02em;

			@media screen and (max-width: $screen-m) {
				margin-bottom: 8px;
				font-size: 36px;
			}

			@media screen and (max-width: $screen-xs) {
				font-size: 32px;
			}
		}
		.export-button-container {
			display: flex;
			align-items: flex-start;
			padding: 8px 12px;
			width: 250px;
			gap: 6px;

			@media screen and (max-width: $screen-s) {
				width: 100%;
				margin-bottom: 24px;
				padding: 8px 0;
			}
		}
	}

	.subtitle {
		font-weight: 600;
		font-size: 28px;
		color: var(--color-neutral-900);
		letter-spacing: -0.02em;

		@media screen and (max-width: $screen-xs) {
			margin-bottom: 24px;
		}
	}

	.date-picker-container {
		display: flex;

		@media screen and (max-width: $screen-m) {
			flex-direction: column;
		}

		.date-picker {
			@media screen and (max-width: $screen-l) {
				margin-bottom: 24px;
			}

			.text {
				margin-bottom: 4px;
				font-weight: 500;
				font-size: 14px;
				color: var(--color-generic-black);
			}

			.date {
				margin-right: 16px;

				@media screen and (max-width: $screen-xs) {
					width: 100%;
					margin-right: 0;
				}
			}
		}
	}

	.filter-container {
		display: flex;
		flex-direction: row;
		gap: 24px;
		align-items: center;
		margin-bottom: 24px;

		.search-bar {
			display: flex;
			width: 453px;
			height: 40px;
			padding: 12px;
			align-items: center;
			gap: 10px;
			border-radius: 8px;
			border: 1px solid var(--neutral-300, #d1d5db);
			background: var(--generic-white, #fff);
			input {
				width: 100%;
				height: 100%;
				border: none;
				font-size: 14px;
				color: var(--color-neutral-900);
				background: transparent;
				&:focus {
					outline: none;
				}
			}
		}

		@media screen and (max-width: $screen-m) {
			flex-direction: column;
			align-items: flex-start;

			.filter {
				width: 100%;
			}
			.search-bar {
				width: 100%;
			}
		}
	}

	.content-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		justify-content: center;
		gap: 24px;
		.loader {
			margin-top: 80px;
		}
		.history-table,
		.pagination {
			margin-top: 24px;
		}
	}

	.pagination {
		margin-right: auto;
		margin-left: auto;
	}
}
