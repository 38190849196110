.root {
	position: fixed;
	z-index: 4;
	top: 0;
	left: 256px;
	padding: 16px;
	border-radius: 0px 0px 16px 0px;
	background-color: var(--color-generic-white);
	transition: left 0.2s linear;

	.title-container {
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.title {
			font-weight: 700;
			font-size: 32px;
			letter-spacing: -0.02em;
			color: var(--color-generic-black);
		}

		.cross-icon {
			cursor: pointer;
		}
	}

	.notification-container {
		margin-bottom: 8px;
		padding: 12px;
		width: 416px;
		background: var(--color-neutral-50);
		border-radius: 8px;
		user-select: none;
		cursor: pointer;
		transition: background-color 0.2s linear;

		&:hover {
			background: var(--color-primary-100);
		}

		.tx-id {
			margin-bottom: 8px;
			font-weight: 700;
			font-size: 18px;
			color: var(--color-neutral-900);
		}

		.error-container {
			margin-bottom: 8px;
			display: flex;
			align-items: center;

			.error {
				margin-right: 16px;
				font-weight: 600;
				font-size: 14px;
				letter-spacing: 0.01em;
				text-transform: uppercase;
				color: var(--color-neutral-700);
			}

			.message {
				font-weight: 400;
				font-size: 14px;
				color: var(--color-neutral-500);
			}
		}

		.date {
			font-weight: 400;
			font-size: 14px;
			color: var(--color-neutral-500);
		}
	}
}

.root-medium {
	left: 72px;
}

.root-open {
	left: 256px;
}

.root-top-menu {
	top: 80px;
	left: 0;
	width: 100%;
	border-radius: 0px 0px 16px 16px;

	.notification-container {
		width: 100%;
	}
}
