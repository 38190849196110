@import "Themes/constants.scss";

body {
	--color-neutral-50: #{$color-neutral-50};
	--color-neutral-100: #{$color-neutral-100};
	--color-neutral-200: #{$color-neutral-200};
	--color-neutral-300: #{$color-neutral-300};
	--color-neutral-400: #{$color-neutral-400};
	--color-neutral-500: #{$color-neutral-500};
	--color-neutral-600: #{$color-neutral-600};
	--color-neutral-700: #{$color-neutral-700};
	--color-neutral-800: #{$color-neutral-800};
	--color-neutral-900: #{$color-neutral-900};

	--color-warning-50: #{$color-warning-50};
	--color-warning-100: #{$color-warning-100};
	--color-warning-200: #{$color-warning-200};
	--color-warning-300: #{$color-warning-300};
	--color-warning-400: #{$color-warning-400};
	--color-warning-500: #{$color-warning-500};
	--color-warning-600: #{$color-warning-600};
	--color-warning-700: #{$color-warning-700};
	--color-warning-800: #{$color-warning-800};
	--color-warning-900: #{$color-warning-900};

	--color-destructive-50: #{$color-destructive-50};
	--color-destructive-100: #{$color-destructive-100};
	--color-destructive-200: #{$color-destructive-200};
	--color-destructive-300: #{$color-destructive-300};
	--color-destructive-400: #{$color-destructive-400};
	--color-destructive-500: #{$color-destructive-500};
	--color-destructive-600: #{$color-destructive-600};
	--color-destructive-700: #{$color-destructive-700};
	--color-destructive-800: #{$color-destructive-800};
	--color-destructive-900: #{$color-destructive-900};

	--color-success-50: #{$color-success-50};
	--color-success-100: #{$color-success-100};
	--color-success-200: #{$color-success-200};
	--color-success-300: #{$color-success-300};
	--color-success-400: #{$color-success-400};
	--color-success-500: #{$color-success-500};
	--color-success-600: #{$color-success-600};
	--color-success-700: #{$color-success-700};
	--color-success-900: #{$color-success-900};
	--color-success-800: #{$color-success-800};

	--color-generic-white: #{$color-generic-white};
	--color-destructive-black: #{$color-generic-black};
}

[theme-mode] {
	--color-primary-50: #{$color-primary-50};
	--color-primary-100: #{$color-primary-100};
	--color-primary-200: #{$color-primary-200};
	--color-primary-300: #{$color-primary-300};
	--color-primary-400: #{$color-primary-400};
	--color-primary-500: #{$color-primary-500};
	--color-primary-600: #{$color-primary-600};
	--color-primary-700: #{$color-primary-700};
	--color-primary-800: #{$color-primary-800};
	--color-primary-900: #{$color-primary-900};
}

[theme-mode="dark"] {
	--color-primary-200: #{$color-primary-200};
	--color-primary-300: #{$color-primary-300};
	--color-primary-400: #{$color-primary-400};
	--color-primary-500: #{$color-primary-500};
	--color-primary-600: #{$color-primary-600};
	--color-primary-700: #{$color-primary-700};
}
