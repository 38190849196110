.root {
	width: max-content;
	padding: 6px;
	border: 1px solid var(--color-neutral-100);
	border-radius: 8px;
	background-color: var(--color-generic-white);

	.text {
		display: inline-block;
		padding: 6px 16px;
		font-weight: 400;
		font-size: 16px;
		color: var(--color-neutral-500);
		user-select: none;
		cursor: pointer;
		transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

		&:hover {
			color: var(--color-neutral-900);
			background-color: var(--color-neutral-50);
		}
	}

	.active {
		font-weight: 500;
		color: var(--color-neutral-900);
		border-radius: 4px;
		background-color: var(--color-neutral-100);
	}
}
