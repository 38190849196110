@import "Themes/constants.scss";

.root {
	padding: 4px 12px;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	border-radius: 24px;
	color: var(--color-neutral-50);
	width: fit-content;
	max-height: 24px;
}

.verified_on_chain {
	background-color: #8ccc82;
}

.verifying_on_chain {
	background-color: #844BFC;
}

.attempting {
	background-color: #7375F2;
}

.queued {
	background-color: #9CA3AF;
}

.abandoned {
	background-color: var(--color-destructive-600);
}

.not_found {
	background-color: var(--color-warning-400);
}
