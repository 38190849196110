@import "Themes/constants.scss";
.title {
	word-break: break-all;
}

.root {
	.hash-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 24px;
		gap: 16px;
		max-width: 638px;
		background: var(--color-neutral-50);
		border-radius: 12px;
		@media screen and (max-width: $screen-mobile) {
			padding: 16px;
			gap: 16px;
			width: 326px;
			height: 368px;
		}
		.subtitle {
			margin-bottom: 8px;
			font-weight: 600;
			font-size: 14px;
			color: var(--color-neutral-900);
		}

		.value {
			font-weight: 400;
			font-size: 14px;
			color: var(--color-neutral-700);
		}
	}
	.container {
		padding: 12px 8px;

		.subtitle {
			margin-bottom: 8px;
			font-weight: 600;
			font-size: 14px;
			color: var(--color-neutral-900);
		}

		.status-list {
			display: grid;
			grid-template-columns: 2fr 2fr;
			gap: 8px;
			max-width: 356px;
			font-size: small;
			font-weight: 300;
		}

		.value {
			font-weight: 400;
			font-size: 14px;
			color: var(--color-neutral-700);
			word-break: break-all;
			padding-top: 8px;

			.splitted {
				display: flex;
				flex-direction: row;
				gap: 24px;
				.first {
					min-width: 98px;
					min-height: 24px;
					border-bottom: 1px solid var(--color-neutral-300);
				}
				.second {
					min-width: 188px;
					min-height: 24px;
					border-bottom: 1px solid var(--color-neutral-300);
				}
			}
		}
	}

	.section-title {
		margin-top: 24px;
		font-weight: 600;
		font-size: 16px;
		color: var(--color-neutral-900);
		padding-bottom: 20px;
	}

	.divider {
		width: 100%;
		height: 1px;
		background: var(--color-neutral-300);
	}
}
