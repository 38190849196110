@import "Themes/constants.scss";

.root {
	.title {
		font-weight: 700;
		font-size: 40px;
		letter-spacing: -0.02em;
		color: var(--color-neutral-900);
	}

	.time-picker-container {
		margin-top: 64px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		@media screen and (max-width: $screen-l) {
			flex-direction: column;
			align-items: flex-start;
		}

		.date-picker-container {
			display: flex;

			:first-child {
				margin-right: 16px;
			}

			@media screen and (max-width: $screen-m) {
				flex-direction: column;
			}

			.date-picker {
				@media screen and (max-width: $screen-l) {
					margin-bottom: 24px;
				}

				.text {
					margin-bottom: 4px;
					font-weight: 500;
					font-size: 14px;
					color: var(--color-generic-black);
				}
			}
		}
	}

	.line-charts-container,
	.bar-chart-container {
		margin-top: 64px;
	}

	.subtitle {
		font-weight: 600;
		font-size: 28px;
		letter-spacing: -0.02em;
		color: var(--color-neutral-900);
	}

	.line-charts-container {
		.line-charts {
			display: flex;
			margin-top: 24px;

			:not(:last-child) {
				margin-right: 24px;
			}

			@media screen and (max-width: $screen-m) {
				flex-direction: column;
				align-items: flex-start;

				:not(:last-child) {
					margin-right: 0;
					margin-bottom: 24px;
				}
			}
		}
	}

	.bar-chart-container {
		.header {
			display: flex;
			justify-content: space-between;

			@media screen and (max-width: $screen-m) {
				flex-direction: column;
				align-items: flex-start;
			}

			.filter-container {
				display: flex;
				gap: 16px;

				@media screen and (max-width: $screen-m) {
					margin-top: 24px;
				}

				@media screen and (max-width: $screen-s) {
					flex-direction: column;
					align-items: flex-start;
					gap: 8px;
					width: 100%;
				}

				.filter {
					width: 100%;
				}
			}
		}

		.bar-chart {
			margin-top: 24px;
		}
	}
}
