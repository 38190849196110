@import "Themes/constants.scss";

.root {
	width: 100%;
	overflow: auto;

	.table {
		padding: 8px;
		border-radius: 8px;
		background-color: var(--color-generic-white);
		min-width: 100%;
	}
}
