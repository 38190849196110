$screen-xl: 2559px;
$screen-l: 1440px;
$screen-m: 1023px;
$screen-s: 767px;
$screen-xs: 424px;
$screen-mobile: 390px;

$custom-easing: cubic-bezier(0.645, 0.045, 0.355, 1);

$color-neutral-50: #f9fafb;
$color-neutral-100: #f3f4f6;
$color-neutral-200: #e5e7eb;
$color-neutral-300: #d1d5db;
$color-neutral-400: #9ca3af;
$color-neutral-500: #6b7280;
$color-neutral-600: #4b5563;
$color-neutral-700: #374151;
$color-neutral-800: #1f2937;
$color-neutral-900: #111827;

$color-primary-50: #f5f5ff;
$color-primary-100: #e1e1fe;
$color-primary-200: #c3c4fe;
$color-primary-300: #9b9dfd;
$color-primary-400: #7375fd;
$color-primary-500: #4b4efc;
$color-primary-600: #1e22fb;
$color-primary-700: #0408e7;
$color-primary-800: #0306ba;
$color-primary-900: #02058d;

$color-success-50: #f0fdf4;
$color-success-100: #dcfce7;
$color-success-200: #bbf7d0;
$color-success-300: #86efac;
$color-success-400: #4ade80;
$color-success-500: #22c55e;
$color-success-600: #16a34a;
$color-success-700: #15803d;
$color-success-800: #166534;
$color-success-900: #14532d;

$color-warning-50: #fffbeb;
$color-warning-100: #fef3c7;
$color-warning-200: #fde68a;
$color-warning-300: #fcd34d;
$color-warning-400: #fbbf24;
$color-warning-500: #f59e0b;
$color-warning-600: #d97706;
$color-warning-700: #b45309;
$color-warning-800: #92400e;
$color-warning-900: #78350f;

$color-destructive-50: #fef2f2;
$color-destructive-100: #fee2e2;
$color-destructive-200: #fecaca;
$color-destructive-300: #fca5a5;
$color-destructive-400: #f87171;
$color-destructive-500: #ef4444;
$color-destructive-600: #dc2626;
$color-destructive-700: #b91c1c;
$color-destructive-800: #991b1b;
$color-destructive-900: #7f1d1d;

$color-generic-white: #ffffff;
$color-generic-black: #000000;
