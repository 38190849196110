@import "Themes/constants.scss";

.root {
	padding: 4px 12px;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	border-radius: 24px;
	color: var(--color-neutral-50);
	width: fit-content;
	max-height: 24px;
}

.open {
	background: var(--Primary-400, #7375fd);
}

.in_progress {
	background-color: #8ccc82;
}

.pending {
	background-color: #d97706;
}

.resolved {
	background-color: #02058d;
}

.closed {
	background-color: #6b7280;
}

.reopened {
	background-color: #dc2626;
}

.cancelled {
	background-color: #000000;
}


