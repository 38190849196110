.root {
	width: max-content;
	height: 36px;
	display: flex;
	background-color: var(--color-generic-white);

	:not(:last-child) {
		margin-right: 8px;
	}

	.cell,
	.page-number {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 400;
		font-size: 14px;
		color: var(--color-neutral-700);
		transition: background-color 0.2s ease-in-out;
		user-select: none;

		&:hover {
			border-radius: 6px;
			background-color: var(--color-neutral-50);
			cursor: pointer;
		}

		.icon {
			path {
				stroke: var(--color-neutral-500);
			}
		}

		.margin-right {
			margin-right: 8px;
		}

		.margin-left {
			margin-left: 8px;
		}
	}

	.page-number {
		width: 36px;
	}

	.active {
		border-radius: 6px;
		color: var(--color-primary-500);
		background-color: var(--color-primary-50);
	}
}
