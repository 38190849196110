.root {
	position: relative;
	display: flex;
	align-items: center;
	user-select: none;
	cursor: pointer;

	&:active {
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0.2;
			background-color: var(--color-background);
			border-radius: 3px;
		}
	}
}
