@import "Themes/constants.scss";

.root {
	position: relative;
	display: flex;
	
	.on-drag{
		overflow: hidden;
		min-height: 100vh;
		min-width: 100vw;
		position: fixed;
		pointer-events: none;
		z-index: 3;
	}

	@media screen and (max-width: $screen-xs) {
		flex-direction: column;
		justify-content: space-between;
		min-height: 100vh;
	}

	.content-container {
		width: 100%;
		max-width: var(--content-max-width);
		margin: var(--template-margin);
		overflow: auto;
		height: 100%;

		.content {
			padding: 32px;

			@media screen and (max-width: $screen-m) {
				padding: 32px 16px;
			}

			@media screen and (max-width: $screen-xs) {
				padding-top: calc(80px + 32px);
			}
		}

		.no-padding {
			padding: 0;

			@media screen and (max-width: $screen-m) {
				padding: 0;
			}
		}
	}
}
