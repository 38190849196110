@import "Themes/constants.scss";

.root {
	.cell {
		white-space: nowrap;
		padding: 16px 24px;
		font-weight: 400;
		font-size: 14px;
		text-align: left;
		color: var(--color-neutral-700);
	}

	tr {
		cursor: pointer;
	}

	.download-button {
		display: flex;
		width: 100%;
		padding: 8px 12px;
		justify-content: center;
		align-items: center;
		gap: 6px;
		border-radius: 6px;
		border: 1px solid var(--neutral-300, #d1d5db);
	}

	.cell:first-child {
		font-weight: 500;
		color: var(--color-primary-500);
	}
}
