@import "Themes/constants.scss";

@keyframes loadbar-animation {
	from {
		transform: scaleX(1);
	}
	to {
		transform: scaleX(0);
	}
}

@keyframes slide-left {
	from {
		opacity: 0;
		transform: translateX(120%);
	}
	to {
		opacity: 1;
		transform: translate(0%);
	}
}

@keyframes slide-left2 {
	from {
		opacity: 0;
		transform: translateX(120%);
	}
	to {
		opacity: 1;
		transform: translate(0%);
	}
}

.root {
	pointer-events: all;
	position: relative;
	padding: 16px;
	background: var(--color-generic-white);
	box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
	border-radius: 5px;

	animation-name: slide-left;
	animation-duration: 400ms;
	animation-timing-function: $custom-easing;
	opacity: 1;
	&[data-will-close="true"] {
		animation-duration: 200ms;
		opacity: 0;
		transform: translateX(120%);
		animation-name: slide-left2;
		animation-direction: reverse;
	}

	.loadbar {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		height: 2px;
		background: var(--color-neutral-5);
		border-radius: 5px;
		transform-origin: right;
		transform: scaleX(0);
		animation: loadbar-animation var(--data-duration) linear;
	}

	.title-container {
		font-family: var(--font-primary);
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: var(--color-neutral-8);
		display: flex;
		align-items: center;
		justify-content: space-between;

		.cross {
			cursor: pointer;
		}
	}

	.text-container {
		font-family: var(--font-primary);
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: var(--color-neutral-6);
		margin-top: 8px;
		margin-bottom: 16px;
	}

	a {
		text-decoration: none;
	}
}
