@import "Themes/constants.scss";

.root {
	display: flex;
	width: 100%;
	height: calc(100vh - 80px);
	overflow: hidden;

	.right-part,
	.left-part {
		flex: 1;
	}

	.left-part {
		margin: auto;
		padding: 0 40px;

		.form-container {
			margin: auto;
			padding: 24px;
			width: 488px;
			border-radius: 12px;
			background-color: var(--color-generic-white);

			@media screen and (max-width: $screen-s) {
				width: 100%;
			}

			.title {
				margin-bottom: 40px;
				font-weight: 700;
				font-size: 28px;
				letter-spacing: -0.02em;
				text-align: center;
				color: var(--color-neutral-900);
			}

			.input-container {
				display: flex;
				flex-direction: column;
				margin-bottom: 20px;

				.label {
					margin-bottom: 6px;
					font-weight: 500;
					font-size: 14px;
					color: var(--color-generic-black);
				}

				.input {
					padding: 10px 12px;
					border: 1px solid var(--color-neutral-300);
					border-radius: 8px;
					background: var(--color-generic-white);

					&::placeholder {
						font-weight: 400;
						font-size: 14px;
						color: var(--color-neutral-400);
					}
				}
			}

			.error {
				font-size: 14px;
				font-weight: 400;
				color: var(--color-destructive-500);
			}

			.login-button {
				margin-top: 46px;
				display: flex;
				justify-content: center;
				width: 100%;
			}
		}
	}

	.right-part {
		@media screen and (max-width: $screen-m) {
			display: none;
		}
	}
}
